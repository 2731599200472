import compassIcon from "../assets/img/mission.svg"
import globeIcon from "../assets/img/globe.svg"
import targetIcon from "../assets/img/goals.svg"

export const AboutUs = () => {
    return(
        <section className="aboutus" id="aboutus">
            <div className="about-us-container">
                <div className="mission-container">
                    <img className="about-us-icon" src={compassIcon} alt=""/>
                    <h2>Mission</h2>
                    <p>
                    As a premier fashion tech company,  that unites fashion professionals and empowers them to bring their innovative designs from  Concept to Catwalk.
                    </p>
                </div>
                <div className="mission-container">
                    <img className="about-us-icon" src={globeIcon} alt=""/>
                    <h2>What We Do</h2>
                    <p>
                    Nurturing a community of fashion professionals to collaborate and thrive collectively, we provide our members with a secure platform for reviewing portfolios, reading ratings, and accessing pricing information—ensuring transparency and fairness for all.                    </p>
                </div>
                <div className="mission-container">
                    <img className="about-us-icon" src={targetIcon} alt=""/>
                    <h2>Goal</h2>
                    <p>
                    Revolutionize the fashion industry with an ecosystem that seamlessly streamlines the entire process for fashion professionals. Experience fashion at your fingertips, from fabric to finish.
                    </p>
                </div>
            </div>
        </section>
    )
}
